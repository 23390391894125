export function index(){	
    const swipeOption = {
		loop: true,
		slidesPerView: 'auto',
		spaceBetween: 48,
		centeredSlides: true,
		autoplay: {
			delay: 2000,
			disableOnInteraction: false,
		},
		speed: 400,
		grabCursor: true
    }
	const swiper = new Swiper('.site-picture .swiper-container' , swipeOption);
	
	return swiper;
}