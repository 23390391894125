export function photo(rest){
	const compornent = {
		data: function(){
			return {
				data:  []
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		template:
		`
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="items in data" class="swiper-slide" :style="{ 'background-image': 'url('+items.acf.photo+')' }"><span>{{ items.title.rendered }}</span></div>
            </div>
        </div>
		`
	}
	
	return compornent;
}